<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesLoadingOpen = ref(false)

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesLoadingOpen
        };
    },
    page: {
    title: "Pays",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Pays",
      items: [
        {
          text: "Définition",
        },
        {
          text: "Pays",
          active: true,
        },
      ],
      errors: [],
      country: {},
      countries: [],
      continents: [],
      name: "",
      continent_id: "",
      isoCode: "",
      dialCode: "",
      loading: false,
      status: 201,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('countries'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.doesLoadingOpen = false
        this.countries = data
      })
    },
    loadcontinents() {
      fetch(Helper.route('continents'), Helper.requestOptionsJSON())
      .then(response => {
          Helper.tokenChecker(response.status)
          console.log(response);
          return response.json()
      })
      .then(data => {
        
        this.continents = data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let body = {
        "isoCode": this.isoCode,
        "dialCode": this.dialCode,
        "continent": {
          "id": this.continent_id
        },
        "name": this.name
      }
      console.log(body);
      fetch(Helper.route('countries'), Helper.requestOptionsJSON("POST", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        
        this.loading = false
        if (this.status == 201) {
          this.name = ""
          this.doesAddOpen = false
          this.countries.push(data) 
          Swal.fire("Pays ajouter !", "Le Pays a été enregistré avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "country_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let body = {
        "isoCode": this.isoCode,
        "dialCode": this.dialCode,
        "continent": {
          "id": this.continent_id
        },
        "name": this.name
      }
      fetch(Helper.route('countries/'+this.country.id), Helper.requestOptionsJSON("PUT", body))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.countries = this.countries.map(i => i.id !== this.country.id ? i : data)
          Swal.fire("Pays modifier !", "Le Pays a été modifié avec succès.", "success");
        } else {
            this.errors.name = Helper.getError(data, "country_name")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('countries/'+this.country.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        if (this.status == 200) {
          this.countries = this.countries.filter(i => i.id !== this.country.id)
          this.loading = false
          this.country = null
          this.doesDeleteOpen = false
          Swal.fire("Pays supprimer !", "Le Pays a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Pays non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    initItem(country, action="edit") {
      this.country = country
      this.initData(country)
      if (action == "delete") {
        this.doesDeleteOpen = true
      } else {
        this.doesEditOpen = true
      }
    },
    initData(item = null) {
      this.isoCode = item != null ? item.isoCode : ""
      this.dialCode = item != null ? item.dialCode : ""
      this.continent_id = item != null ? item.continent.id : ""
      this.name = item != null ? item.name : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadcontinents()
    this.doesLoadingOpen = false
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.initData(null)
        this.country = null
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.initData(null)
        this.country = null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des pays</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>pays</code>.
            </p>

            <div class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Continent</th>
                    <th>Pays</th>
                    <th>dialCode</th>
                    <th>Iso Code</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(country, i) in countries" :key="country.id">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ country.continent.name }}</td>
                    <td>{{ country.name }}</td>
                    <td>{{ country.dialCode }}</td>
                    <td>{{ country.isoCode }}</td>
                    <td class="hstack">
                        <b-button class="btn-sm ms-2" @click="initItem(country, 'edit')" variant="success">
                            <i class="bx bx-edit font-size-16 align-middle mx-1"></i>
                        </b-button>
                        <b-button variant="danger" @click="initItem(country, 'delete')" class="ms-2 btn-sm">
                          <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                        </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            
          </div>
        </div>
      </div>
    </div>


    <!-- LOADING MODAL -->
    <b-modal v-model="doesLoadingOpen" id="modal-center" centered size="md" title="Chargement..."
      title-class="font-18" hide-footer>
      <div class="text-center">
        <div class="spinner-border text-primary-" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </b-modal>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        size="md"
        title="Ajouter un pays"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez le continent</label>
                <select required v-model="continent_id" class="form-control">
                    <option value=""></option>
                    <option v-for="continent in continents" :key="continent.id" :value="continent.id">{{ continent.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Nom du pays</label>
              <input type="text" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Indicatif</label>
              <input type="text" required v-model="dialCode" class="form-control">
              <span class="text-danger mt-2" v-if="errors.dialCode">{{ errors.dialCode }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Code Iso</label>
              <input type="text" required v-model="isoCode" class="form-control">
              <span class="text-danger mt-2" v-if="errors.isoCode">{{ errors.isoCode }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="md"
        title="Modifier un pays"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <label for="">Sélectionnez le continent</label>
                <select required v-model="continent_id" class="form-control">
                    <option value=""></option>
                    <option v-for="continent in continents" :key="continent.id" :value="continent.id">{{ continent.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Nom du pays</label>
              <input type="text" required v-model="name" class="form-control">
              <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Indicatif</label>
              <input type="text" required v-model="dialCode" class="form-control">
              <span class="text-danger mt-2" v-if="errors.dialCode">{{ errors.dialCode }}</span>
            </div>
            <div class="col-md-12 mb-3">
              <label for="">Code Iso</label>
              <input type="text" required v-model="isoCode" class="form-control">
              <span class="text-danger mt-2" v-if="errors.isoCode">{{ errors.isoCode }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>


    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer un pays"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer le pays <b>{{ country?.name }}</b> ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>


  </Layout>
</template>
